import React, { useEffect, useState } from 'react';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import bell from "../../Assets/Images/icon/bell-01.png";
import circle from "../../Assets/Images/icon/info-circle.png";
import avatar from "../../Assets/Images/icon/Avatar.png";
import arrow from "../../Assets/Images/icon/downIcon.png";
import { Dropdown } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import { singleJwtMember } from '../../api/member';
import { isAuthenticate } from '../../api/auth';
import { DOCOTEAM_API as API } from '../../config';


const Header = ({ onValueChange }: any) => {
    const navigate = useNavigate();
    const [collapsed, setCollapsed] = React.useState(false);
    const [memberInfo, setMemberInfo] = useState<any>({});
    const [userImage, setUserImage] = useState("");
    const [userName, setUserName] = useState("");
    const handleClick = () => {
        setCollapsed(!collapsed)
        onValueChange(collapsed);
    };
    const location = useLocation();
    const pathArray = location.pathname.split('/');
    const urlParams = pathArray[pathArray.length - 1];


    const logoutUser = () => {
        localStorage.removeItem("company");
        return navigate(`/`);
    }
    let auth = isAuthenticate();

    console.log('auth', auth.token);
    useEffect(() => {
        singleJwtMember(auth.token).then((data) => {
            console.log('auth', data);
            if (data.statusCode !== 200) {

            }
            else {
                setUserImage(data.data.member_image);
                setUserName(data.data.first_name);
            }
        })
    }, []);


    return (
        <>
            <div className='topNavbar'>
                <div className='contentHeading'>
                    <button className="sb-button" onClick={handleClick}><FontAwesomeIcon icon={faBars} /></button>
                    {urlParams === "member" ? <p>Members</p> : ""}
                    {urlParams === "spaces" ? <p>Spaces</p> : ""}
                    {urlParams === "files" ? <p>Files</p> : ""}
                    {urlParams === "tickets" ? <p>Ticket</p> : ""}
                    {urlParams === "task" ? <p>Tasks</p> : ""}
                </div>
                <div className='rightNavbar'>
                    <button><img src={circle} alt="circle" /></button>
                    <button><img src={bell} alt="bell" /></button>
                    <button className='memberImg'>
                        {userImage && userImage.length ? <img src={`${API}/${userImage}`} alt="logo" />
                            : <img src={avatar} alt="bell" />
                        }
                    </button>
                    <Dropdown>
                        <Dropdown.Toggle id="dropdown-basic">
                            {userName && userName.length ? userName : "Admin"} <img src={arrow} alt="arrow" />
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Dropdown.Item href="#">Profile</Dropdown.Item>
                            <Dropdown.Item href="#" onClick={logoutUser}>Logout</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
            </div>
        </>
    )
}

export default Header