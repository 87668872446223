
import { post, put } from "./base-api";
// admin login
export const loginAdmin = async (body = {}) => {
    return post(`/adminLogin`, body);
};

// admin login
export const memberPassword = async (body = {}) => {
    return put(`/memberPassword`, body);
};
