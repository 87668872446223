import React, { useState, useEffect, useRef } from 'react';
import "./Messenger.css";
import Layout from '../../Component/Layout/Layout';
import avatar from "../../Assets/Images/icon/Avatar.png";
import emoji from "../../Assets/Images/icon/face-smile.png";
import fileShare from "../../Assets/Images/icon/link-01.png";
import plusBtn from "../../Assets/Images/icon/Button.png";
import onlineShow from "../../Assets/Images/icon/online.png";
import messageFile from "../../Assets/Images/icon/Mask Group.png";
import more from "../../Assets/Images/icon/more.png"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { faPaperPlane } from '@fortawesome/free-regular-svg-icons';

const Messenger = () => {
    const [messages, setMessages] = useState<string[]>([]);
    const [chatMessage, setChatMessage] = useState<string[]>([]);
    const [input, setInput] = useState<string>('');
    const chatContainerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        scrollToBottom();
    }, [messages,input]);

    const scrollToBottom = () => {
        if (chatContainerRef.current) {
            chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
        }
    };

    const messageStart = () => {
        if (input.trim() !== '') {
            setChatMessage(prevMessages => [...prevMessages, input]);
            setInput('');
        }
    }

    return (
        <Layout>
            <div className='mainContent'>
                <div className="chat">
                    <div className="contacts">
                        <div className="all-messages-parent">
                            <div className="all-messages">All Messages</div>
                            <div className="button">
                                {/* <img className="info-circle-icon" alt="" src={plusBtn} /> */}
                                <FontAwesomeIcon icon={faPlus} />
                            </div>
                        </div>
                        <div className="contacts-child" />
                        <div className="contact">
                            <div className="contact1">
                                <div className="avatar-parent">
                                    <div className="avatar">
                                        <img className="avatar-icon1" alt="" src={avatar} />
                                        <div className="avatar-online-indicator">
                                            <img alt="" src={onlineShow} />
                                        </div>
                                    </div>
                                    <div className="text">
                                        <div className="bogdan-krivenchenko">Sophia Lewis</div>
                                        <div className="hi-how-are">
                                            Hi! How are things with the...
                                        </div>
                                    </div>
                                </div>
                                <div className="parent">
                                    <div className="div16">10:30 AM</div>
                                    <div className="ellipse" />
                                </div>
                            </div>
                            <div className="contact1">
                                <div className="avatar-parent">
                                    <img className="avatar-icon1" alt="" src={avatar} />
                                    <div className="text">
                                        <div className="buzz-coworking">Emily Johnson</div>
                                        <div className="hi-how-are">
                                            Hi! How are things with the...
                                        </div>
                                    </div>
                                </div>
                                <div className="parent">
                                    <div className="div16">9:15 AM</div>
                                    <div className="ellipse" />
                                </div>
                            </div>
                            <div className="contact1">
                                <div className="avatar-parent">
                                    <img className="avatar-icon1" alt="" src={avatar} />
                                    <div className="text">
                                        <div className="buzz-coworking">Olivia Mague</div>
                                        <div className="hi-how-are">
                                            Hi! How are things with the...
                                        </div>
                                    </div>
                                </div>
                                <div className="parent">
                                    <div className="div16">Yesterday</div>
                                    <div className="ellipse" />
                                </div>
                            </div>
                            <div className="contact1">
                                <div className="avatar-parent">
                                    <img className="avatar-icon1" alt="" src={avatar} />
                                    <div className="text">
                                        <div className="buzz-coworking">Michael Cox</div>
                                        <div className="hi-how-are">
                                            Hi! How are things with the...
                                        </div>
                                    </div>
                                </div>
                                <div className="wrapper">
                                    <div className="div16">Wednesday</div>
                                </div>
                            </div>
                            <div className="contact1">
                                <div className="avatar-parent">
                                    <img className="avatar-icon1" alt="" src={avatar} />
                                    <div className="text">
                                        <div className="buzz-coworking">Ethan Howel</div>
                                        <div className="hi-how-are">
                                            Hi! How are things with the...
                                        </div>
                                    </div>
                                </div>
                                <div className="wrapper">
                                    <div className="div16">Tuesday</div>
                                </div>
                            </div>
                            <div className="contact1">
                                <div className="avatar-parent">
                                    <img className="avatar-icon1" alt="" src={avatar} />
                                    <div className="text">
                                        <div className="buzz-coworking">David Hanger</div>
                                        <div className="hi-how-are">
                                            Hi! How are things with the...
                                        </div>
                                    </div>
                                </div>
                                <div className="wrapper">
                                    <div className="div16">Sunday</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="chat1">
                        <div className="user-header">
                            <div className="user-parent">
                                <div className="user">
                                    <div className="avatar-parent6">
                                        <div className="avatar1">
                                            <img className="avatar-icon1" alt="" src={avatar} />
                                            <div className="avatar-online-indicator">
                                                <img alt="" src={onlineShow} />
                                            </div>
                                        </div>
                                        <div className="dropdown">
                                            <div className="sarah-kline">Zoe Harper</div>
                                            <div className="member">Member</div>
                                        </div>
                                    </div>
                                </div>
                                <img className="more-icon" alt="" src={more} />
                            </div>
                        </div>
                        <div className="chat2" ref={chatContainerRef}>
                            <div className="friday-january-26th-parent">
                                <div className="friday-january-26th">Friday, January 26th</div>
                                <div className="div24">
                                    <div className="message">
                                        <div className="avatar1">
                                            <img className="avatar-icon1" alt="" src={avatar} />
                                            <div className="avatar-online-indicator">
                                                <img alt="" src={onlineShow} />
                                            </div>
                                        </div>
                                        <div className="message1">
                                            <div className="hihow-are-things-with-our-ill-wrapper">
                                                <div className="hihow-are-things">
                                                    Hi there, good morning! I was wondering if it's possible
                                                    to get a replacement key fob?
                                                </div>
                                            </div>
                                            <div className="wrapper3">
                                                <div className="div16">8:30 AM</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="message2">
                                        <div className="message1">
                                            <div className="hihow-are-things-with-our-ill-container">
                                                <div className="hihow-are-things1">
                                                    I accidentally broke mine this morning!
                                                </div>
                                            </div>
                                            <div className="wrapper3">
                                                <div className="div16">8:31 AM</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="message4">
                                        <div className="message1">
                                            <div className="message6">
                                                <img
                                                    className="mask-group-icon"
                                                    alt=""
                                                    src={messageFile}
                                                />
                                            </div>
                                            <div className="wrapper5">
                                                <div className="div16">8:33 AM</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="message4">
                                        <div className="message1">
                                            <div className="message6">
                                                <img
                                                    className="mask-group-icon"
                                                    alt=""
                                                    src={messageFile}
                                                />
                                            </div>
                                            <div className="wrapper5">
                                                <div className="div16">8:33 AM</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="message7">
                                    <div className="message8">
                                        <div className="hi-im-working-on-the-final-sc-wrapper">
                                            <div className="hihow-are-things">
                                                Good morning! Absolutely, I can have another one made for
                                                you. There will be a $20 fee - I will add it to your next
                                                invoice.
                                            </div>
                                        </div>
                                        <div className="wrapper6">
                                            <div className="div16">10:25 AM</div>
                                        </div>
                                    </div>
                                    <img className="avatar-icon1" alt="" src={avatar} />
                                </div>
                                <div className="message7">
                                    <div className="message8">
                                        <div className="hi-im-working-on-the-final-sc-wrapper">
                                            <div className="hihow-are-things">
                                                Good morning! Absolutely, I can have another one made for
                                                you. There will be a $20 fee - I will add it to your next
                                                invoice.
                                            </div>
                                        </div>
                                        <div className="wrapper6">
                                            <div className="div16">10:25 AM</div>
                                        </div>
                                    </div>
                                    <img className="avatar-icon1" alt="" src={avatar} />
                                </div>
                                {chatMessage.map((message, index) => (
                                 <div className="message7" key={`chat` + index}>
                                 <div className="message8">
                                     <div className="hi-im-working-on-the-final-sc-wrapper">
                                         <div className="hihow-are-things">
                                            {message}
                                         </div>
                                     </div>
                                     <div className="wrapper6">
                                         <div className="div16">10:25 AM</div>
                                     </div>
                                 </div>
                                 <img className="avatar-icon1" alt="" src={avatar} />
                             </div>
                                ))}
                            </div>
                            <div className="avatar-parent7">
                                <img className="avatar-icon" alt="" src={avatar} />
                                <div className="input-field">
                                    <div className="input-with-label">
                                        <div className="label1">Email Address</div>
                                        <div className="input">
                                            <img
                                                className="search-md-icon"
                                                alt=""
                                                src="/searchmd.svg"
                                            />
                                            <div className="content3">
                                                <textarea className="text9 form-control" value={input} onChange={(e) => setInput(e.target.value)} placeholder='Send a message...' />
                                            </div>
                                            <FontAwesomeIcon className="info-circle-icon" onClick={messageStart} icon={faPaperPlane} />
                                            <img
                                                className="info-circle-icon"
                                                alt=""
                                                src={emoji}
                                            />
                                            <img
                                                className="info-circle-icon"
                                                alt=""
                                                src={fileShare}
                                            />
                                        </div>
                                    </div>
                                    <div className="hint-text">
                                        This is a hint text to help user.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default Messenger