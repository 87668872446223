import React, { useState, useEffect } from 'react';
import Layout from '../../Component/Layout/Layout';
import "./Task.css";
import { Col, Container, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faEllipsis, faPlus } from '@fortawesome/free-solid-svg-icons';
import { faClock, faEye } from '@fortawesome/free-regular-svg-icons';
import arrow from "../../Assets/Images/icon/doubleArrow.png";
import AddTask from '../../Component/AddTask/AddTask';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { getTaskList, updateStatus } from '../../api/task';
import { DOCOTEAM_API as API } from '../../config';
import { showNotifications } from '../../CommonFunction/toaster';
import { ToastContainer } from 'react-toastify';
import { getDueDateStatus } from '../../CommonFunction/Function';

interface TaskInterface {
    id: string;
    status: string;
    title: string;
    description: string;
    assign: string;
    dueDate: string;
    task_image: string;
    created_at: string;
    assigned_members: any;
}

interface Column {
    id: string;
    title: string;
    tasks: TaskInterface[];
}


const Task = () => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const [status, setStatus] = useState("");
    const [pendingList, setPendingList] = useState([]);
    const [doingList, setDoingList] = useState([]);
    const [doneList, setDoneList] = useState([]);

    useEffect(() => {
        getTaskList("PENDING").then((data) => {
            setPendingList(data)
        });
        getTaskList("DOING").then((data) => {
            setDoingList(data)
        });
        getTaskList("DONE").then((data) => {
            setDoneList(data)
        });
    }, [show])
    const [columns, setColumns] = useState<Column[]>([]);
    useEffect(() => {
        const initialColumns = [
            {
                id: 'PENDING',
                title: 'PENDING',
                tasks: pendingList,
            },
            {
                id: 'DOING',
                title: 'DOING',
                tasks: doingList,
            },
            {
                id: 'DONE',
                title: 'DONE',
                tasks: doneList,
            },
        ];

        // Set the initial columns
        setColumns(initialColumns);
    }, [pendingList, doingList, doneList, show]);


    const addTask = (status: string) => {
        setShow(true);
        setStatus(status);
    }




    const onDragEnd = (result: any) => {
        const { source, destination, draggableId } = result;
        if (!destination) return;
        if (
            source.droppableId === destination.droppableId &&
            source.index === destination.index
        ) {
            return;
        }
        const sourceColumn = columns.find(
            (column) => column.id === source.droppableId
        );
        const destinationColumn = columns.find(
            (column) => column.id === destination.droppableId
        );
        if (!sourceColumn || !destinationColumn || sourceColumn === destinationColumn) {
            return;
        }
        const task = sourceColumn.tasks.find((task) => task.id === draggableId);
        const newSourceTasks = [...sourceColumn.tasks];
        newSourceTasks.splice(source.index, 1);
        const newDestinationTasks = [...destinationColumn.tasks];
        newDestinationTasks.splice(destination.index, 0, task as TaskInterface);
        const newColumns = columns.map((column) => {
            if (column.id === sourceColumn.id) {
                return { ...column, tasks: newSourceTasks };
            } else if (column.id === destinationColumn.id) {
                return { ...column, tasks: newDestinationTasks };
            } else {
                return column;
            }
        });

        let statusUpdate = {
            "status": destinationColumn.id
        }
        updateStatus(draggableId, statusUpdate).then((data) => {
            showNotifications('success', "Task Change Successfully !!");
        });

        setColumns(newColumns);
    };






    return (
        <>
            <Layout>
                <div className='mainTaskContent'>
                    <ToastContainer />
                    <Container>
                        <Row>
                            <Col md={12}>
                                <div className="allTaskFilter">
                                    <p>All Tasks</p>
                                    <div className="filterBtn">
                                        <button><FontAwesomeIcon icon={faEye} /> All Tasks <FontAwesomeIcon icon={faChevronDown} /></button>
                                        <button><img src={arrow} alt="arrow" /> Due Date <FontAwesomeIcon icon={faChevronDown} /></button>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>

                    <Container>
                        <Row>
                            <DragDropContext onDragEnd={onDragEnd}>
                                {columns && columns.map((column) => (
                                    <Col md={4}>
                                        <div className="pendingList">
                                            <div className="taskTopHeading">
                                                <p>{column.title}</p>
                                                <FontAwesomeIcon icon={faEllipsis} />
                                            </div>
                                            <Droppable droppableId={column.id}>
                                                {(provided: any) => (
                                                    <div
                                                        {...provided.droppableProps}
                                                        ref={provided.innerRef}
                                                        style={{
                                                            overflow: "auto",
                                                            height: 'calc(100vh - 288px)',
                                                        }}
                                                    >
                                                        {column.tasks.map((task, index) => (
                                                            <Draggable
                                                                key={task.id}
                                                                draggableId={task.id}
                                                                index={index}

                                                            >
                                                                {(provided: any) => (
                                                                    <div className="taskCard"
                                                                        ref={provided.innerRef}
                                                                        {...provided.draggableProps}
                                                                        {...provided.dragHandleProps}
                                                                    >
                                                                        <div className="taskHeading">
                                                                            <p>{task.title}</p>
                                                                            <FontAwesomeIcon icon={faEllipsis} />
                                                                        </div>
                                                                        {task.task_image ? <div className="taskImg">
                                                                            <img src={`${API}/${task.task_image}`} alt='task' />
                                                                        </div> : ""}

                                                                        <div className="taskDate">
                                                                            <p className={getDueDateStatus(task.dueDate) === "Yesterday" ? "pastDate" : getDueDateStatus(task.dueDate) === "Today" ? "dueDate" : "futureDate"}>
                                                                                <FontAwesomeIcon icon={faClock} />
                                                                                <span>{getDueDateStatus(task.dueDate)}</span>
                                                                            </p>
                                                                        </div>
                                                                        <div className="taskMember">
                                                                            {task.assigned_members && task.assigned_members.split(',').map((filePath:any, index:number) => (
                                                                                <img key={index} src={`${API}/${filePath.trim()}`} alt="" />
                                                                            ))}
                                                                        </div>
                                                                    </div>
                                                                )}

                                                            </Draggable>
                                                        ))}
                                                        {provided.placeholder}
                                                        <div className="addTask">
                                                            <button onClick={() => addTask(column.title)}><FontAwesomeIcon icon={faPlus} /> Add a Task</button>
                                                        </div>
                                                    </div>
                                                )}
                                            </Droppable>
                                        </div>
                                    </Col>
                                ))}
                            </DragDropContext>
                        </Row>
                    </Container>
                </div>
                <AddTask show={show} status={status} setShow={setShow} handleClose={handleClose} />
            </Layout >
        </>
    )
}

export default Task