import React, { useState, useEffect } from 'react'
import { Col, Container, Modal, Row } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faXmark } from '@fortawesome/free-solid-svg-icons';
import assignmentIcon from "../../Assets/Images/icon/assignmentIcon.png";
import "./AssignMember.css"
import { Link } from 'react-router-dom';
import { singleSpaces } from '../../api/spaces';
import { DOCOTEAM_API as API } from '../../config';

interface AssignMemberProps {
    handleAssignClose: () => void;
    assignShow: boolean;
    setAssignShow: (type: boolean) => void;
    spaceId: string;
}


const AssignMember = ({ spaceId, assignShow, setAssignShow, handleAssignClose }: AssignMemberProps) => {
    const [authValue, setAuthValue] = useState(false);
    const [name, setName] = useState("");
    const [rate, setRate] = useState("");
    const [spaceImage, setSpaceImage] = useState("");

    useEffect(() => {
        singleSpaces(spaceId).then((data) => {
            setName(data.data && data.data.name);
            setRate(data.data && data.data.rate);
            setSpaceImage(data.data && data.data.space_image);
        })
    }, [spaceId]);
    const authClick = () => {
        setAuthValue(!authValue)
    }

    return (
        <>
            <Modal show={assignShow} onHide={handleAssignClose} centered size="lg">
                <div className="addMemberForm">
                    <button className='closeModal' onClick={handleAssignClose}>
                        <FontAwesomeIcon icon={faXmark} />
                    </button>
                    <Container>
                        <Row>
                            <Col md={12}>
                                <div className='addMemberHeading'>
                                    <img src={assignmentIcon} alt="member" />
                                    <p>Create Assignment</p>
                                </div>
                            </Col>
                            <Col md={12}>
                                <div className="memberSpaces">
                                    <div className="memberInfos">
                                        <img src={assignmentIcon} alt="" />
                                        <div><p>Emma Clarkson</p><span>emma@buzz.com</span></div>
                                    </div>
                                    <div className='arrowIcon'>
                                        <FontAwesomeIcon icon={faChevronRight} />
                                    </div>
                                    <div className="memberInfos">
                                        <img src={`${API}/${spaceImage}`} alt="" />
                                        <div><p>{name}</p><span>${rate}</span></div>
                                    </div>
                                </div>
                            </Col>
                            <Col md={12}>
                                <div className="assignName">
                                    <div className="generateInvoice">
                                        <h5>Generate Invoice?</h5>
                                        <div className="authToggle">
                                            {authValue === true ?
                                                <label className="switch">
                                                    <input type="checkbox" onClick={authClick} defaultChecked />
                                                    <span className="slider round"></span>
                                                </label> :
                                                <label className="switch">
                                                    <input type="checkbox" onClick={authClick} />
                                                    <span className="slider round"></span>
                                                </label>}
                                        </div>
                                    </div>
                                    {authValue ? <>
                                        <div className="generateInvoice">
                                        <h6>Amount Owed Today</h6>
                                        <div className="memberInput amount">
                                            <span>$</span>
                                            <input type="text" placeholder='Rate' className='form-control' />
                                            <Link to="/">Calculate Pro-Rated Rent</Link>
                                        </div>
                                    </div>
                                    <div className="generateInvoice">
                                        <h6>Renewal Date</h6>
                                        <div className='memberInput'>
                                            <input type="text" placeholder='Month' className='form-control' />
                                        </div>
                                    </div>
                                    <div className="generateInvoice">
                                        <h6>Renewal Frequency</h6>
                                        <div className='memberInput'>
                                            <input type="text" placeholder='Month' className='form-control' />
                                        </div>
                                    </div>
                                    </> : <></>}
                                    
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </Modal>
        </>
    )
}

export default AssignMember