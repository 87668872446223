
import { get, post, put } from "./base-api";
import axios from 'axios';
import { DOCOTEAM_API as API } from '../config';
// task add
export const taskAdd = async (body = {}) => {
    try {
        const response = await axios.post(`${API}/taskCreate`, body, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        return response.data;
    } catch (error: any) {
        return error.response.data;
    }
};

// task list
export const getTaskList = async (status: string) => {
    return get(`/taskList/${status}`);
};
// task status update
export const updateStatus = async (id: string, body = {}) => {
    return put(`/taskStatus/${id}`,body);
};