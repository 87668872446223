import React, { useState } from 'react'
import Layout from '../../Component/Layout/Layout'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft, faPlus } from '@fortawesome/free-solid-svg-icons'
import "./InvoiceDetails.css";
import avatar from "../../Assets/Images/icon/Avatar.png";
import download from "../../Assets/Images/icon/download-02.png";
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { singleInvoice } from '../../api/invoice';
import moment from 'moment';
import { DOCOTEAM_API as API } from '../../config';
import { usePDF } from 'react-to-pdf';

const InvoiceDetails = () => {
    const { id } = useParams();
    const [invoiceDetail, setInvoiceDetail] = useState<any>({});
    const { toPDF, targetRef } = usePDF({filename: `${invoiceDetail && invoiceDetail.invoiceId}.pdf`});

    useEffect(() => {
        if (id) {
            singleInvoice(id).then((data) => {
                console.log('invoice', data);
                setInvoiceDetail(data.data)
            })
        }
    }, [])

    return (
        <Layout>
            <div className='mainContent'>
                <div className="invoiceHeading">
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb m-0 ms-2">
                            <li className="breadcrumb-item">Finances</li>
                            <li className="breadcrumb-item">Billing</li>
                            <li className="breadcrumb-item active" aria-current="page">Invoice {invoiceDetail && invoiceDetail.invoiceId}</li>
                        </ol>
                    </nav>
                </div>
                <div className="createInvoice d-flex">
                    <div className="topLine">
                        <div className='tableHeading'>
                            <h6><FontAwesomeIcon icon={faArrowLeft} /> Invoice {invoiceDetail && invoiceDetail.invoiceId}</h6>
                        </div>
                        <button><FontAwesomeIcon icon={faPlus} /> Manually Record Payment</button>
                    </div>
                </div>
                <div className="invoiceInformation d-flex">

                    <div className="invoiceView" ref={targetRef}>
                        <div className="invoiceDetail">
                            <div className="invoiceTop">
                                <div className="invoiceId">
                                    <h1>Invoice : <span>{invoiceDetail && invoiceDetail.invoiceId}</span></h1>
                                </div>
                                <div className="invoiceDate">
                                    <p>Issued: <span>{invoiceDetail && moment(invoiceDetail.created_at).format('MMMM D, YYYY')}</span></p>
                                    <p>Due: <span>{invoiceDetail && moment(invoiceDetail.dueDate).format('MMMM D, YYYY')}</span></p>
                                </div>
                            </div>
                            <div className="invoiceInfo">
                                <div className="invoiceId">
                                    <p>Invoice ID</p>
                                    <h6>{invoiceDetail && invoiceDetail.invoiceId}</h6>
                                </div>
                                <div className="invoiceId">
                                    <p>Status</p>
                                    <h6 className='status'><span className='paid'>Paid</span></h6>
                                </div>
                                <div className="invoiceId">
                                    <p>Payment Date</p>
                                    <h6>{invoiceDetail && invoiceDetail.paymentDate ? <>{moment(invoiceDetail.paymentDate).format('MMMM D, YYYY')}</> : "N/A"}</h6>
                                </div>
                                <div className="invoiceId">
                                    <p>Payment Method</p>
                                    <h6>{invoiceDetail && invoiceDetail.method ? <>{invoiceDetail.method}</> : "N/A"}</h6>
                                </div>
                            </div>
                            <div className="invoiceItem">
                                <div className="itemHeading">
                                    <p>Item</p>
                                    <p>Amount</p>
                                </div>
                                <div className="itemList">
                                    <div className="itemName">
                                        <img src={avatar} alt="avatar" style={{ borderRadius: "50%" }} /> {invoiceDetail && invoiceDetail.assignment}
                                    </div>
                                    <div className="itemPrice">
                                        {invoiceDetail && invoiceDetail.amount ? <>${invoiceDetail.amount}</> : "N/A"}
                                    </div>
                                </div>
                                <div className="itemTotal">
                                    <p>Total Amount <span>{invoiceDetail && invoiceDetail.amount ? <>${invoiceDetail.amount}</> : "N/A"}</span></p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='rightInvoice'>
                        <div className="invoiceMember">
                            <h1>Member Info</h1>
                            <div className="memberDetails">
                                <img src={`${API}/${invoiceDetail.member_image}`} width="40px" height="40px" alt="avatar" style={{ borderRadius: "50%" }} />
                                <div>
                                    <p>{invoiceDetail && invoiceDetail.first_name} {invoiceDetail && invoiceDetail.last_name}</p>
                                    <span>{invoiceDetail && invoiceDetail.email}</span>
                                </div>
                            </div>
                        </div>
                        <div className="invoiceNotes">
                            <h1>Member Info</h1>
                            <p>{invoiceDetail && invoiceDetail.notes ? <>{invoiceDetail.notes}</> : "No notes"}</p>
                        </div>
                        <div className="invoiceDownload">
                            <p>Download Invoice</p>
                            <button onClick={() => toPDF()}><img src={download} alt="download" /> Download PDF</button>
                        </div>
                    </div>

                </div>
            </div>
        </Layout>
    )
}

export default InvoiceDetails