import React, { useState, forwardRef, useRef, useEffect } from 'react'
import { Col, Container, Modal, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faPlus, faXmark } from '@fortawesome/free-solid-svg-icons';
import taskIcon from "../../Assets/Images/icon/task.png";
import { DOCOTEAM_API as API } from '../../config';
import "./AddTask.css";
import attachment from "../../Assets/Images/icon/attachment.png";
import assign from "../../Assets/Images/icon/assign.png";
import clock from "../../Assets/Images/icon/clock.png";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import DatePicker from 'react-datepicker';
import UploadTask from './UploadTask';
import uploadFile from "../../Assets/Images/icon/uploadIcon.png";
import fileFormat from "../../Assets/Images/icon/file-05.png";
import { convertBytesToSize } from '../../CommonFunction/Function';
import trash from "../../Assets/Images/icon/red-trash.png";
import { searchMember } from '../../api/member';
import { showNotifications } from '../../CommonFunction/toaster';
import { v4 as uuidv4 } from 'uuid';
import { taskAdd } from '../../api/task';
import { ToastContainer } from 'react-toastify';

interface AddTaskProps {
    handleClose: () => void;
    show: boolean;
    setShow: (type: boolean) => void;
    status: string;
}

const AddTask = ({ show, setShow, status, handleClose }: AddTaskProps) => {
    const [content, setContent] = useState("");
    const [dueDate, setDueDate] = useState<any>(new Date());
    const [file, setFile] = useState("");
    const [title, setTitle] = useState("");
    const [members, setMembers] = useState([]);
    const [uploadedFiles, setUploadedFiles] = useState<File[]>([]);
    const [shares, setShares] = useState<any>([]);
    const [shareMember, setShareMember] = useState<any>([]);

    const [searchTerm, setSearchTerm] = useState("");
    const [uploadShow, setUploadShow] = useState(false);
    const handleUploadClose = () => setUploadShow(false);

    useEffect(() => {
        searchMember(searchTerm).then((data) => {
            setMembers(data.results);
        });

    }, [searchTerm]);

    var modules: any = {
        toolbar: [
            [{ size: ["small", false, "large", "huge"] }],
            ["bold", "italic", "underline", "strike", "blockquote"],
            [{ list: "ordered" }, { list: "bullet" }],
            ["link"],
            [
                { list: "ordered" },
                { list: "bullet" },
                { indent: "-1" },
                { indent: "+1" },
                { align: [] }
            ],
            [{ "color": ["#000000", "#e60000", "#ff9900", "#ffff00", "#008a00", "#0066cc", "#9933ff", "#ffffff", "#facccc", "#ffebcc", "#ffffcc", "#cce8cc", "#cce0f5", "#ebd6ff", "#bbbbbb", "#f06666", "#ffc266", "#ffff66", "#66b966", "#66a3e0", "#c285ff", "#888888", "#a10000", "#b26b00", "#b2b200", "#006100", "#0047b2", "#6b24b2", "#444444", "#5c0000", "#663d00", "#666600", "#003700", "#002966", "#3d1466", 'custom-color'] }],
        ]
    };

    var formats: any = [
        "header", "height", "bold", "italic",
        "underline", "strike", "blockquote",
        "list", "color", "bullet", "indent",
        "link", "align", "size",
    ];

    const handleProcedureContentChange = (content: string) => {
        setContent(content)
    };

    const dueDateChange = (date: any) => {
        const selectedDate = new Date(date);
        selectedDate.setHours(0, 0, 0, 0);
        setDueDate(selectedDate)
    }

    const CustomDatePickerInput: React.FC<any> = forwardRef(({ value, onClick }, ref) => (
        <button className="taskDate" onClick={onClick}>
            {value}
            <FontAwesomeIcon icon={faChevronDown} />
        </button>
    ));


    // add files
    const wrapperRef = useRef<HTMLInputElement>(null);
    const onFileDrop = (event: any) => {
        const imageFile = event.target.files && event.target.files[0];
        setFile(event.target.files[0])
        setUploadedFiles([imageFile]);
    }

    // remove file
    const removeFile = () => {
        setUploadedFiles([]);
    }

    const shareList = (share: any) => {
        const shareExists = shares.some((existingShare: any) => existingShare.id === share.id);
        if (!shareExists) {
            setShares([...shares, share.id]);
            setShareMember([...shareMember, share]);
        } else {
            showNotifications('error', 'Share already exists in the list');
        }
    }

    const taskCrete = () => {
        let task = {
            "id": uuidv4(),
            "status": status,
            "title": title,
            "description": content,
            "assign": `${shares}`,
            "task_image": file,
            "dueDate": dueDate,
        }

        taskAdd(task).then((data) => {
            showNotifications('success', "Task add successfully!!");
            setShow(false)
            setShareMember([]);
            setShares([]);
            setMembers([]);
            setContent("")
        })

    }
    return (
        <>
            <Modal show={show} onHide={handleClose} centered size="lg">
                <ToastContainer />
                <div className="addMemberForm">
                    <button className='closeModal' onClick={handleClose}>
                        <FontAwesomeIcon icon={faXmark} />
                    </button>
                    <Container>
                        <Row>
                            <Col md={12}>
                                <div className='addMemberHeading'>
                                    <img src={taskIcon} alt="member" />
                                    <p>Add Task</p>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12}>
                                <div className="taskName">
                                    <input type="text" onChange={(e) => setTitle(e.target.value)} className='form-control' placeholder='Task Title' />
                                </div>
                                <div className="taskDescription">
                                    <h6>Description</h6>
                                    <ReactQuill
                                        theme="snow"
                                        modules={modules}
                                        formats={formats}
                                        placeholder="Enter a description..."
                                        onChange={handleProcedureContentChange}
                                        value={content}
                                    />
                                </div>
                                <div className="taskOptions">
                                    <p><img src={assign} alt="assign" /> Assignee</p>
                                    <button><FontAwesomeIcon icon={faPlus} /></button>
                                </div>
                                <div className="taskMemberList">
                                    <div className="taskMember mt-2">
                                        {shareMember.map((filePath: any, index: number) => (
                                            <img key={index} src={`${API}/${filePath.member_image}`} alt="" />
                                        ))}
                                    </div>
                                    <input onChange={(e) => setSearchTerm(e.target.value)} type="text" spellCheck="false" placeholder='Search Member' className='form-control' />
                                    <ul className='searchMember'>
                                        {members && members.map((member: any, index) => (
                                            <li key={`member` + index} onClick={() => shareList(member)}>
                                                <img src={`${API}/${member.member_image}`} alt="" />
                                                <span>{member.first_name}</span>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                                <div className="taskOptions">
                                    <p><img src={clock} alt="clock" /> Due Date</p>
                                    <button><FontAwesomeIcon icon={faPlus} /></button>
                                </div>
                                <div className="dateShow">
                                    <DatePicker selected={dueDate} onChange={dueDateChange} placeholderText="Select a date" dateFormat="MM/dd/yyyy" customInput={<CustomDatePickerInput />} />
                                </div>
                                <div className="taskOptions">
                                    <p><img src={attachment} alt="attachment" /> Attachments</p>
                                    <button onClick={() => setUploadShow(true)}><FontAwesomeIcon icon={faPlus} /></button>
                                </div>
                                <div className="taskFiles mt-3">
                                    <div ref={wrapperRef} className="drop-file-input">
                                        <div className="drop-file-input__label">
                                            <img src={uploadFile} alt="" />
                                            <p><span>Click to upload</span> or drag and drop</p>
                                        </div>
                                        <input type="file" value="" onChange={onFileDrop} />
                                    </div>
                                    {uploadedFiles && uploadedFiles.map((file: any, index: number) =>
                                        <div className="uploadFileShow">
                                            <div className="fileFormat">
                                                <img src={fileFormat} alt="file" />
                                            </div>
                                            <div className="fileName">
                                                <p>{file.name}</p>
                                                <span>{convertBytesToSize(file.size)} – 100% uploaded</span>
                                            </div>
                                            <div className="fileDelete" onClick={removeFile}>
                                                <img src={trash} alt="trash" />
                                            </div>
                                        </div>
                                    )}
                                </div>
                                <div className='taskBtn d-flex justify-content-end'>
                                    <button onClick={taskCrete}>Add Task</button>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <UploadTask setFile={setFile} uploadedFiles={uploadedFiles} setUploadedFiles={setUploadedFiles} uploadShow={uploadShow} setUploadShow={setUploadShow} handleUploadClose={handleUploadClose} />
            </Modal>
        </>
    )
}

export default AddTask