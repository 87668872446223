import imgExtension from "../Assets/Images/icon/feature-image.png";
import fileExtension from "../Assets/Images/icon/feature-file.png";
import videoExtension from "../Assets/Images/icon/feature-video.png";
import unknownExtension from "../Assets/Images/icon/feature-unknown.png";

export function convertBytesToSize(bytes:any) {
  const kilobyte = 1024;
  const megabyte = kilobyte * 1024;
  const gigabyte = megabyte * 1024;

  if (bytes < kilobyte) {
      return bytes + ' Byte';
  } else if (bytes < megabyte) {
      return Math.round(bytes / kilobyte) + ' KB';
  } else if (bytes < gigabyte) {
      return Math.round(bytes / megabyte) + ' MB';
  } else {
      return Math.round(bytes / gigabyte) + ' GB';
  }
}

export const getFileType = (extension: string) => {
  const imageExtensions = ['jpg', 'jpeg', 'png', 'gif'];
  const videoExtensions = ['mp4', 'avi', 'mov'];
  const documentExtensions = ['pdf', 'doc', 'docx', 'txt'];

  if (imageExtensions.includes(extension)) {
    return imgExtension;
  } else if (videoExtensions.includes(extension)) {
    return videoExtension;
  } else if (documentExtensions.includes(extension)) {
    return fileExtension;
  } else {
    return unknownExtension;
  }
};
// due date
export function getDueDateStatus(dueDateString:any) {
  // Parse the due date string into a Date object
  const dueDate = new Date(dueDateString);

  // Get the current date and time
  const currentDate = new Date();

  // Calculate the difference in milliseconds
  const difference = dueDate.getTime() - currentDate.getTime();

  // Convert difference to days
  const daysDifference = Math.floor(difference / (1000 * 60 * 60 * 24));

  // Check if the due date is in the past or the future
  if (daysDifference < 0) {
    // Due date is in the past
    if (daysDifference === -1) {
      return 'Yesterday';
    } else {
      return `${Math.abs(daysDifference)} days ago`;
    }
  } else if (daysDifference === 0) {
    // Due date is today
    return 'Today';
  } else if (daysDifference === 1) {
    // Due date is tomorrow
    return 'Tomorrow';
  } else {
    // Due date is in the future, return the actual date
    return dueDate.toLocaleDateString('en-US', { month: 'short', day: 'numeric' });
  }
}

