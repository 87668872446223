import React, { useState, forwardRef, useRef, useEffect } from 'react';
import "./CreateInvoice.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faChevronDown, faPlus } from '@fortawesome/free-solid-svg-icons';
import { Col, Dropdown, Row } from 'react-bootstrap';
import Layout from '../../Component/Layout/Layout';
import DatePicker from 'react-datepicker';
import calenderIcon from "../../Assets/Images/icon/calendar.png";
import { v4 as uuidv4 } from 'uuid';
import { invoiceAdd } from '../../api/invoice';
import { showNotifications } from '../../CommonFunction/toaster';
import { useForm } from 'react-hook-form';
import { getMemberList } from '../../api/member';
import { ToastContainer } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

const CreateInvoice = () => {
    const navigate = useNavigate();
    const [selectedTag, setSelectedTag] = useState('');
    const [dueDate, setDueDate] = useState<any>("");
    const [member, setMember] = useState<string>("");
    const [memberId, setMemberId] = useState<string>("");
    const [memberList, setMemberList] = useState([]);
    const handleSelect = (eventKey: string | null) => {
        if (eventKey) {
            
            const [id, firstName, lastName] = eventKey.split(" ");
            setMemberId(id);
            setMember(`${firstName} ${lastName}`);
        }
    };

    const handleSelectAssign = (eventKey: string | null) => {
        if (eventKey) {
            setSelectedTag(eventKey);
        }
    };

    

    const form = useRef(null);
    const { handleSubmit, register, setValue } = useForm();

    useEffect(() => {
        getMemberList().then((data) => {

          if (data.statusCode !== 200) {
    
          }
          else {
            setMemberList(data.members);
          }
        })
      }, []);


    const dueDateChange = (date: any) => {
        const selectedDate = new Date(date);
        selectedDate.setHours(0, 0, 0, 0);
        setDueDate(selectedDate)
    }
    const CustomDatePickerInput: React.FC<any> = forwardRef(({ value, onClick }, ref) => (
        <button className="calenderBox requestInputForm" onClick={onClick}>
            {value}
            <img src={calenderIcon} alt="calender" />
        </button>
    ));
    const CustomDateFormatInput: React.FC<any> = forwardRef(({ value, onClick }, ref) => (
        <button className="calenderBox requestInputForm" onClick={onClick}>
            {value}
            <p>MM/DD/YYYY</p>
            <img src={calenderIcon} alt="calender" />
        </button>
    ));


    let onSubmit = (user: any) => {
        console.log('form.current', user);

        if (user) {
            const { invoiceId, notes } = user;

            let invoice = {
                "id": uuidv4(),
                "invoiceId": invoiceId,
                "dueDate": dueDate,
                "member": memberId,
                "assignment": selectedTag,
                "notes": notes,
            }


            invoiceAdd(invoice).then((data) => {
                if (data.statusCode !== 201) {
                    showNotifications('error', 'Wrong information');
                }
                else {
                    showNotifications('success', 'Member add successfully');
                    setValue('invoiceId', "")
                    setValue('notes', "")
                }
                return navigate("/billing");
            })
        }
    }


    return (
        <>
            <Layout>
                <ToastContainer />
                <div className='mainContent'>
                    <div className="invoiceHeading">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb m-0 ms-2">
                                <li className="breadcrumb-item">Finances</li>
                                <li className="breadcrumb-item">Billing</li>
                                <li className="breadcrumb-item active" aria-current="page">Create New Invoice</li>
                            </ol>
                        </nav>
                    </div>
                    <div className="createInvoice">
                        <div className="topLine">
                            <div className='tableHeading'>
                                <h6><FontAwesomeIcon icon={faArrowLeft} /> All Invoices</h6>
                            </div>
                        </div>
                        <div className="invoiceForm">
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <Row>
                                    <Col md={6}>
                                        <div className="memberInput">
                                            <label>Invoice ID</label>
                                            <input type="text" {...register("invoiceId", { required: true })} placeholder='Invoice ID' className='form-control' required />
                                        </div>
                                        <div className="invoiceInput">
                                            <label>Member</label>
                                            <Dropdown onSelect={handleSelect}>
                                                <Dropdown.Toggle variant="" className="custom-toggle">
                                                    {member.length ? member : "Choose Member"}
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu>
                                                   {memberList.map((member:any)=><Dropdown.Item eventKey={`${member.id} ${member.first_name} ${member.last_name}`}>{member.first_name} {member.last_name}</Dropdown.Item>)}
                                                    
                                                </Dropdown.Menu>
                                            </Dropdown>
                                            <FontAwesomeIcon icon={faChevronDown} />
                                        </div>
                                    </Col>

                                    <Col md={6}>
                                        <div className="calenderInput">
                                            <label>Due Date</label>
                                            {dueDate && dueDate ?
                                            <div className='dueDateFormat'>
                                                    <DatePicker selected={dueDate} placeholderText="Select a date" onChange={dueDateChange} dateFormat="MM/dd/yyyy" customInput={<CustomDatePickerInput />} />
                                            </div>
                                                : <div className='dueDateFormat'>
                                                    <DatePicker placeholderText="Select a date" onChange={dueDateChange} dateFormat="MM/dd/yyyy" customInput={<CustomDateFormatInput />} />
                                                </div>
                                            }
                                        </div>
                                        <div className="invoiceInput">
                                            <label>Assignment</label>
                                            <Dropdown onSelect={handleSelectAssign}>
                                                <Dropdown.Toggle variant="" className="custom-toggle">
                                                    {selectedTag.length ? selectedTag : "Choose Assignment"}
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu>
                                                    <Dropdown.Item eventKey="Assign One">Assign One</Dropdown.Item>
                                                    <Dropdown.Item eventKey="Assign Two">Assign Two</Dropdown.Item>
                                                    <Dropdown.Item eventKey="Assign Three">Assign Three</Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                            <FontAwesomeIcon icon={faChevronDown} />
                                        </div>
                                    </Col>
                                    <Col md={12}>
                                        <div className="memberInput">
                                            <label>Note</label>
                                            <textarea placeholder='Enter a note...' {...register("notes", { required: true })} className='form-control' required />
                                        </div>
                                    </Col>
                                    <Col md={12} className='d-flex justify-content-end'>
                                        <button className='invoiceBtn active' type='submit'><FontAwesomeIcon icon={faPlus} /> Create New Invoice</button>
                                    </Col>

                                </Row>
                            </form>


                        </div>
                    </div>
                </div>
            </Layout>
        </>
    )
}

export default CreateInvoice