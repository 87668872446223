import React, { useState, forwardRef } from 'react'
import { Col, Container, Dropdown, Modal, Row } from 'react-bootstrap'
import { ToastContainer } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faXmark } from '@fortawesome/free-solid-svg-icons';
import paymentIcon from "../../Assets/Images/icon/paymentIcon.png";
import avatar from "../../Assets/Images/icon/Avatar.png";
import DatePicker from 'react-datepicker';
import calenderIcon from "../../Assets/Images/icon/calendar.png";
import send from "../../Assets/Images/icon/send.png"
import { invoiceUpdate } from '../../api/invoice';


interface PaymentProps {
    handleClose: () => void;
    show: boolean;
    setShow: (type: boolean) => void;
    invoiceId: string;
}

const RecordPayment = ({ show, setShow, handleClose, invoiceId }: PaymentProps) => {
    const [dueDate, setDueDate] = useState<any>("");
    const [selectedTag, setSelectedTag] = useState('');
    const [amount, setAmount] = useState('');
    const [notes, setNotes] = useState('');
    const dueDateChange = (date: any) => {
        const selectedDate = new Date(date);
        selectedDate.setHours(0, 0, 0, 0);
        setDueDate(selectedDate)
    }
    const CustomDatePickerInput: React.FC<any> = forwardRef(({ value, onClick }, ref) => (
        <button className="calenderBox requestInputForm" onClick={onClick}>
            {value}
            <img src={calenderIcon} alt="calender" />
        </button>
    ));
    const CustomDateFormatInput: React.FC<any> = forwardRef(({ value, onClick }, ref) => (
        <button className="calenderBox requestInputForm" onClick={onClick}>
            {value}
            <p>MM/DD/YYYY</p>
            <img src={calenderIcon} alt="calender" />
        </button>
    ));

    const handleSelectAssign = (eventKey: string | null) => {
        if (eventKey) {
            setSelectedTag(eventKey);
        }
    };

    const paymentAdd  = () => {
        let paymentInfo={
            "amount": amount,
            "paymentDate": dueDate,
            "method": selectedTag,
        }
        invoiceUpdate(invoiceId,paymentInfo).then((data) => {
            setShow(false)
         })
    }
        

    return (
        <>
            <Modal show={show} onHide={handleClose} centered size="lg">
                <ToastContainer />
                <div className="addMemberForm">
                    <button className='closeModal' onClick={handleClose}>
                        <FontAwesomeIcon icon={faXmark} />
                    </button>
                    <Container>
                        <Row>
                            <Col md={12}>
                                <div className='addMemberHeading'>
                                    <img src={paymentIcon} alt="member" />
                                    <p>Record Payment</p>
                                </div>
                            </Col>
                            <Col md={12}>
                                <div className="invoiceMemberInfo">
                                    <h1>Payment From</h1>
                                    <div className="memberInfos">
                                        <img src={avatar} alt="" />
                                        <div>
                                            <p>Emma Clarkson</p>
                                            <span>emma@buzz.com</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="memberInput rate mt-3">
                                    <span>$</span>
                                    <label>Amount</label>
                                    <input type="number" onChange={(e)=>setAmount(e.target.value)} placeholder='Amount' className='form-control' />
                                    <button>USD</button>
                                </div>
                                <div className="calenderInput">
                                    <label>Payment Date</label>
                                    {dueDate && dueDate ?
                                        <div className='dueDateFormat'>
                                            <DatePicker selected={dueDate} placeholderText="Select a date" onChange={dueDateChange} dateFormat="MM/dd/yyyy" customInput={<CustomDatePickerInput />} />
                                        </div>
                                        : <div className='dueDateFormat'>
                                            <DatePicker placeholderText="Select a date" onChange={dueDateChange} dateFormat="MM/dd/yyyy" customInput={<CustomDateFormatInput />} />
                                        </div>
                                    }
                                </div>
                                <div className="invoiceInput">
                                    <label>Payment Method</label>
                                    <Dropdown onSelect={handleSelectAssign}>
                                        <Dropdown.Toggle variant="" className="custom-toggle">
                                            {selectedTag.length ? selectedTag : "Choose Payment Method"}
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <Dropdown.Item eventKey="Paypal">Paypal</Dropdown.Item>
                                            <Dropdown.Item eventKey="Visa Card">Visa Card</Dropdown.Item>
                                            <Dropdown.Item eventKey="Master Card">Master Card</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                    <FontAwesomeIcon icon={faChevronDown} />
                                </div>
                                <div className="memberInput">
                                    <label>Note</label>
                                    <textarea placeholder='Enter a note...' onChange={(e)=>setNotes(e.target.value)} className='form-control' />
                                </div>

                            </Col>
                            <Col md={12} className='d-flex justify-content-end'>
                                <button className='invoiceBtn active' type='submit' onClick={paymentAdd}>Pay Now <img src={send} alt="send" /> </button>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </Modal>
        </>
    )
}

export default RecordPayment