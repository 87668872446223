import { get, post, put } from "./base-api";
import axios from 'axios';
import { DOCOTEAM_API as API } from '../config';
// invoice add
export const invoiceAdd = async (body = {}) => {
    try {
        const response = await axios.post(`${API}/invoiceCreate`, body);
        return response.data;
    } catch (error: any) {
        return error.response?.data;
    }
};

// invoice list
export const getInvoicesList = async (limit: number, page: number) => {
    return get(`/invoicesList?limit=${limit}&&page=${page}`);
};
// single invoice
export const singleInvoice = async (id: string) => {
    return get(`/invoiceSingle/${id}`);
};

// invoice update
export const invoiceUpdate = async (invoiceId: string, body = {}) => {
    console.log('invoiceId',invoiceId);
    
    return put(`/invoicePayment/${invoiceId}`, body);
};
