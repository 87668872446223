import React from 'react';
import Routing from './Routing';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import "react-datepicker/dist/react-datepicker.css";

function App() {
  return (
    <>
    <Routing/>
    </>
  );
}

export default App;
